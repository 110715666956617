import React from "react";
import logo from "../../Assets/img/medlogo.png";
import user from "../../Assets/subscription-plan/user-default.webp";
import icon from "../../Assets/subscription-plan/down-arrow.webp";
import { useState } from "react";

type HeaderProps = {
  onSubscribePressed: () => void;
  onUnSubscribePressed: () => void;
  networkPackage: string | null;
};

const Header = (props: HeaderProps) => {
  let msisdn = localStorage.getItem("msisdn");

  const [isMenuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="navbarWraper">
          <div className="medLogo">
            <img src={logo} width={140} height={60} alt="logo" />
          </div>
          {msisdn ? (
            <>
              {/* loggedin user */}
              <div className="login" onClick={toggleMenu}>
                <div className="user-image">
                  <img src={user} alt="user" />
                </div>
                <div className="dropdown-icon">
                  <img src={icon} alt="icon" />
                </div>

                {isMenuVisible && (
                  <div className="menu">
                    <ul>

                      {props.networkPackage === "prepaid" ? (
                        <li>
                          <a href="/subscription">Subscription Plan</a>
                        </li>
                        // null 
                      ) : (

                        null 
                      )
                      }


                      <li>
                        <a className="subscribe" onClick={props.onUnSubscribePressed}>
                          Unsubscribe
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="navLinks">
                <ul>
                  <li>
                    <a onClick={props.onSubscribePressed} className="subscribe">
                      Subscribe
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
