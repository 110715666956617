const ApiNames = {
  authentication: "authenticateWeb",
  signUp: "user/signupWeb",
  verifyOtp: "user/verifyOtpWeb",
  subscribe: "user/subscribeWeb",
  unsubscribe: "user/unsubscribeWeb",
  getFlow: "user/getFlow",
  awarenessFlow: "user/awarenessFlow",
  checkUser: "user/checkUser",
  subscribeFastFlow: "user/ffSubscribe",
  headerEnrichment: "user/checkHE",
  utmList: "user/utmList",
  checkOperator: "user/checkOperator",
  updatePackage: "user/updatePackage",

};
export default ApiNames;
