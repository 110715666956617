import React, { useEffect, useState } from 'react'
import ApiNames from '../Constants/ApiNames'
import { fetchApiGet } from '../Utils/FetchApi';
import SubscriptionPlan from '../Components/SubscriptionPlan';

type SubscriptionPlanScreenProps = {
  msisdn: string;
  token: string | null;
}

const SubscriptionPlanScreen = (props: SubscriptionPlanScreenProps) => {
  const [packageDetail, setPackageDetail] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.token) {
      console.log(props.token);
      fetchCheckUserApiCall();

    }
  }, [props.token]);

  const fetchCheckUserApiCall = async () => {
    try {
      let response = await fetchApiGet(props.token, `${ApiNames.checkUser}/${props.msisdn}`);
      if (response.status === 0) {
        setPackageDetail(response.currentPackage);
        // localStorage.setItem("subpackage", response.result.subPackage);
      }
      else {
        console.log("Something went wrong, Please try again.")
      }
    } catch (error) {
      console.log("fetchCheck user error", error);
    }
  }

  const onHandleSubscriptionPlanChange = async () => {
    try {
      setIsLoading(true);
      // console.log("Subscription Plan Change Triggered")
      let response = await fetchApiGet(props.token, `${ApiNames.updatePackage}?msisdn=${props.msisdn}`);
      if (response.desc.toLowerCase().includes("package updated successful")) {
        console.log("Package updated successful")
        localStorage.setItem("subpackage", "mt");
        setPackageDetail("mt");
        setIsLoading(false);

        // const date = new Date();
        // localStorage.setItem("packageChangeTime", date.toString());
      }
      else {
        console.log("Something went wrong, Please try again.")
      }
    } catch (error) {
      console.log("Package updated fail error: ", error);
    }
  }

  return (
    <SubscriptionPlan
      isLoading={isLoading}
      packageDetail={packageDetail}
      onHandleSubscriptionPlanChange={onHandleSubscriptionPlanChange}
    />
  )
}

export default SubscriptionPlanScreen