import { useState, useEffect } from "react";
import ApiNames from "../Constants/ApiNames";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import { useSearchParams, useNavigate } from "react-router-dom";
import Landing from "../Components/Landing";

interface ILandingScreen {
  token: string | null;
}

const LandingScreen = (props: ILandingScreen) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";
  const qsPackageName = searchParams.get("package_name")?.toLowerCase()
    ? searchParams.get("package_name")?.toLowerCase()
    : "ms";
  const [msisdn, setMsisdn] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModel, setIsModel] = useState(true);
  const [campaignParameter, setCampaignParameter] = useState(
    searchParams.get("parameter")
      ? searchParams.get("parameter")?.toLocaleLowerCase()
      : searchParams.get("Parameter")
      ? searchParams.get("Parameter")?.toLocaleLowerCase()
      : ""
  );
  const queryParams = searchParams;
  const perameterP = getParameterCaseInsensitive("p");

  useEffect(() => {
    if (props.token) {
      getFlow();
    }
  }, [props.token]);

  const getFlow = async () => {
    try {
      // let data = {
      //   "status": 0,
      //   "result": {
      //     "isSpecialFlow": false,
      //     "fastFlow": false,
      //     "msisdn": "3185719801",
      //     "otp": "",
      //     "milliseconds": 0,
      //     "signIn": true,
      //     "subPackage": null,
      //     "network": null
      //   }
      // };

      let response = await fetchApiGet(
        props.token,
        `${ApiNames.getFlow}?utm_source=${utm_source}`
      );
      // let response = data;
      if (response.status === 0) {
        if (response.result.isSpecialFlow) {
          let _subPackage = "";
          if (response.result.subPackage.toLowerCase().includes("pre")) {
            _subPackage = "prepaid";
          } else {
            _subPackage = "postpaid";
          }

          navigate("/pin", {
            state: {
              isSpecialFlow: response.result.isSpecialFlow,
              isFastFlow: response.result.fastFlow,
              msisdn: response.result.msisdn,
              otp: response.result.otp,
              milliseconds: response.result.milliseconds,
              utm_source: utm_source,
              campaignParameter: campaignParameter,
              subPackage: _subPackage,
              package_name: qsPackageName,
            },
          });
        } else {
          if (response.result.msisdn) {
            if (perameterP === "1") {
              console.log("in");
              setTimeout(() => {
                setIsModel(false);
              }, 2000);
            }
            setMsisdn(response.result.msisdn);
          }
        }
      }
    } catch (error) {
      console.log("get flow error ::", error);
    }
  };

  const onMsisdnChange = (value: string) => {
    if (value === "0" || !value.startsWith("3")) {
      setMsisdn("");
    } else {
      let numRegex = new RegExp("^[0-9]*$");
      if (numRegex.test(value)) {
        setMsisdn(value);
      }
    }
  };

  const onSendOTPPressed = () => {
    console.log("SendOTP Triggered");
    if (msisdn.length === 10) {
      setErrorMsg("");
      checkOperator();
      // sendOTP();
    } else {
      setErrorMsg("Please enter a 10 digits mobile number. e.g 3xxxxxxxxx");
    }
  };

  const checkOperator = async () => {
    try {
      setIsLoading(true);
      let response = await fetchApiGet(
        props.token,
        `${ApiNames.checkOperator}?msisdn=${msisdn}`
      );
      if (response) {
        if (response.network.toLowerCase() === "zong") {
          if (response.desc.toLowerCase().includes("pre")) {
            // localStorage.setItem("networkPackage", "prepaid");
            sendOTP("prepaid");
          } else {
            // localStorage.setItem("networkPackage", "postpaid");
            sendOTP("postpaid");
          }
        } else {
          setIsLoading(false);
          setErrorMsg("This service is only for Zong users.");
        }
      } else {
        setIsLoading(false);
        setErrorMsg("Something went wrong, Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const sendOTP = async (networkPackage: string) => {
    try {
      let data = {
        msisdn: msisdn,
      };
      let response = await fetchApiPost(ApiNames.signUp, props.token, data);
      if (response.status === 0) {
        navigate("/pin", {
          state: {
            msisdn: msisdn,
            utm_source: utm_source,
            campaignParameter: campaignParameter,
            subPackage: networkPackage,
            package_name: qsPackageName,

            // add subpakcage oover here
          },
        });
      } else {
        setIsLoading(false);
        setErrorMsg("Unable to send OTP, Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  function getParameterCaseInsensitive(name: String) {
    for (const [key, value] of queryParams.entries()) {
      if (key.toLowerCase() === name.toLowerCase()) {
        return value;
      }
    }
    return null;
  }

  const onHandleJoinNow = () => {
    setIsModel(false);
  };

  return (
    <Landing
      isLoading={isLoading}
      msisdn={msisdn}
      errorMsg={errorMsg}
      campaignParameter={campaignParameter}
      onMsisdnChange={onMsisdnChange}
      onSendOTPPressed={onSendOTPPressed}
      // isModel={isModel}
      isModel={false}
      onHandleJoinNow={onHandleJoinNow}
      package_name={qsPackageName}
    />
  );
};

export default LandingScreen;
