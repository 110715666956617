import React from "react";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import { Tooltip } from "react-tooltip";
import "../StyleSheets/subscriptionplan.css";
import sehatplan from "../Assets/subscription-plan/sehat-plan-icon.webp";
import family from "../Assets/subscription-plan/family.webp";
import tahafuz from "../Assets/subscription-plan/tahafuz-plan-icon.webp";
import { Spinner } from "react-bootstrap";
import Header from "./Common/Header";

type SubscriptionPlanProps = {
  packageDetail: string | null;
  onHandleSubscriptionPlanChange: () => void;
  isLoading: boolean;
};

const SubscriptionPlan = (props: SubscriptionPlanProps) => {
  return (
    <div className="subscription-plan">
      {/* <Header/> */}
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-xs-12">
            <div className="sub-left">
              <div className="sub-text">
                <div className="heading">
                  <span className="sub">Subscription</span>{" "}
                  <span className="pla">Plans</span>
                </div>
                <div className="sub-text">
                  Zong MediQ offers a comprehensive suite of health and wellness
                  services designed to empower you to take charge of your
                  well-being. We provide two unique subscription plans to cater
                  to your specific needs:
                </div>
              </div>

              <div className="subscription-card">
                {/* Sehat Plan */}

                {props.packageDetail === "ms" ? (
                  <div className="purple-card">
                    <div className="image">
                      <img src={sehatplan} alt="Sehat plan" />
                    </div>
                    <div className="card-heading">Sehat Plan</div>

                    <div className="des">
                      Unlimited General Practitioner chats, free lab samples &
                      delivery, discounted meds - all for your daily health.
                    </div>

                    <div className="price">
                      PKR 5.00+T<span className="day">/Day</span>
                    </div>
                    <div className="link">
                      <a className="disabled" href="#">
                        Subscribed
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="purple-card white-card">
                    <div className="image">
                      <img src={sehatplan} alt="Sehat plan" />
                    </div>
                    <div className="card-heading">Sehat Plan</div>

                    <div className="des">
                      Unlimited General Practitioner chats, free lab samples &
                      delivery, discounted meds - all for your daily health.
                    </div>

                    <div className="price">
                      PKR 5.00+T<span className="day">/Day</span>
                    </div>
                    <div className="link m-link">
                      <span className="tooltiptext">
                        You cannot revert your subscription plan
                      </span>
                      <a
                        data-tooltip-id="my-tooltip-2"
                        className="disabled"
                        href="#"
                      >
                        Subscribe
                      </a>
                    </div>
                  </div>
                )}

                {/* Change the content before deployment */}

                <Tooltip
                  id="my-tooltip-2"
                  place="bottom"
                  content="You cannot revert your subscription plan"
                />

                {/* Tahaffuz Plan */}

                {props.packageDetail === "mt" ? (
                  <div className="purple-card">
                    <div className="image">
                      <img src={tahafuz} alt="Sehat plan" />
                    </div>
                    <div className="card-heading">Tahafuz Plan</div>

                    <div className="des">
                      24/7 doctor chat, discounts on specialists, meds, labs &
                      hospitals. Plus, accidental & life insurance
                    </div>

                    <div className="price">
                      PKR 8.00+T<span className="day">/Day</span>
                    </div>
                    <div className="link ">
                      <a className="disabled" href="#">
                        Subscribed
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="purple-card white-card">
                    <div className="image">
                      <img src={tahafuz} alt="Sehat plan" />
                    </div>
                    <div className="card-heading">Tahafuz Plan</div>

                    <div className="des">
                      24/7 doctor chat, discounts on specialists, meds, labs &
                      hospitals. Plus, accidental & life insurance
                    </div>

                    <div className="price">
                      PKR 8.00+T<span className="day">/Day</span>
                    </div>
                    <div
                      className="link"
                      onClick={props.onHandleSubscriptionPlanChange}
                    >
                      {props.isLoading ? (
                        <a>
                          <Spinner
                            style={{ width: "25px", height: "25px" }}
                          ></Spinner>
                        </a>
                      ) : (
                        <a href="#">Subscribe</a>
                      )}

                      {/* <a href="#">Subscribe</a> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-xs-12">
            <div className="sub-right">
              <img src={family} alt="Family" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
