import React, { useEffect, useState } from "react";
import Home from "../Components/Home";
import { decode } from "base-64";
import { useSearchParams, useNavigate } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import Swal from "sweetalert2";

interface HomeScreen {
  token: string | null;
  msisdn: string;
  removeMsisdn: () => void;
}
const HomeScreen = (props: HomeScreen) => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [subPopUp, setSubPopUp] = useState(false);
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = [
    "Corporate Employer",
    "Insurance Company",
    "Telecom Operator",
    "Wellness Partners",
  ];
  const [tabIndex, SetTabIndex] = useState(0);
  const navigate = useNavigate();
  let networkPackage = localStorage.getItem("networkPackage");
  console.log("networkPackage", networkPackage);

  const onUnSubscribePressed = () => {
    console.log("In pressed");
    Swal.fire({
      title: "Are you sure you want to UnSubscribe ?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        onUnSubscribe();
      }
    });
  };

  const onUnSubscribe = async () => {
    try {
      let msisdn = localStorage.getItem("msisdn");
      let data = {
        msisdn: msisdn,
      };
      let response = await fetchApiPost(
        ApiNames.unsubscribe,
        props.token,
        data
      );
      if (response.status === 0) {
        if (response.result === "Subscriber UnSubscribed.") {
          props.removeMsisdn();
          onUnsubscribeURLReplace();
          localStorage.removeItem("networkPackage");
          localStorage.removeItem("subpackage");
          localStorage.removeItem("msisdn");
          localStorage.removeItem("packageChangeTime");
          // localStorage.removeItem("packageChangeTime");
          Swal.fire("UnSubscribe", "", "success");
        }
      }
    } catch (error) {
      console.log("onunsubscribe pressed ::", error);
    }
  };
  const closePopUp = () => {
    setSubPopUp(false);
  };

  const onClickTabIndex = (index: number) => {
    if (tabIndex !== index) {
      SetTabIndex(index);
    }
  };

  const onUnsubscribeURLReplace = () => {
    // if (window.location.protocol === "https:") {
      let url = window.location.origin + "/home";
      // url = url.replace("https:", "http:");
      window.location.replace(url);
    // }
  }

  const onSubscribePressed = () => {
    if (window.location.protocol === "https:") {
      let url = window.location.origin + "/phone";
      url = url.replace("https:", "http:");
      window.location.replace(url);
    } else {
      navigate("/phone");
    }
  };
  return (
    <Home
      subscriptionStatus={subscriptionStatus}
      tabs={tabs}
      tabIndex={tabIndex}
      subPopUp={subPopUp}
      message={message}
      onUnSubscribePressed={onUnSubscribePressed}
      onClickTabIndex={onClickTabIndex}
      closePopUp={closePopUp}
      onSubscribePressed={onSubscribePressed}
      msisdn={props.msisdn}
      networkPackage={networkPackage}
    />
  );
};

export default HomeScreen;
