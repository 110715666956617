import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../Utils/FetchApi";
import ApiNames from "../Constants/ApiNames";
import { encode } from "base-64";
import tagManagerEvents from "../Utils/GoogleTagManager";
import Awareness from "../Components/Awareness";
// import { encode } from "base-64";

type Props = {
  token: string | null;
};

let adNetwork: string = "";
const AwarenessScreen = (props: Props) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const utm_source = searchParams.get("utm_source")
    ? searchParams.get("utm_source")
    : "";
  const qsPackageName = searchParams.get("package_name")?.toLowerCase()
    ? searchParams.get("package_name")?.toLowerCase()
    : "ms";
  useEffect(() => {
    if (props.token) {
      checkUtm();
      getFlow();
    }
  }, [props.token]);

  const getFlow = async () => {
    try {
      let _qsPackageName = "ms";
      if (qsPackageName === "ms" || qsPackageName === "mt") {
        _qsPackageName = qsPackageName;
      }
      let response = await fetchApiGet(
        props.token,
        `${ApiNames.awarenessFlow}?utm_source=${utm_source}&package_name=${_qsPackageName}`
      );
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          if (qsPackageName === "ms") {
            tagManagerEvents("subscribe", "econTikTokMS");
          } else if (qsPackageName === "mt") {
            tagManagerEvents("subscribe", "econTikTokMT");
          } else {
            tagManagerEvents("subscribe", utm_source);
          }
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("subscribe", "econceptions");
        } else {
          tagManagerEvents("subscribe", utm_source);
        }
        let packageName = "";
        if (response.subPackage.toLowerCase().includes("pre")) {
          packageName = "prepaid";
        } else {
          packageName = "postpaid";
        }

        const date = new Date();
        // localStorage.setItem("packageChangeTime", date.toString());

        moveToHome(response.msisdn, packageName, date);
      } else {
        navigateToHome();
      }
    } catch (error) {
      navigateToHome();

      console.log("get flow error ::", error);
    }
  };

  const checkUtm = async () => {
    try {
      let response = await fetchApiGet(props.token, ApiNames.utmList);
      if (response.status === 200) {
        {
          response.response.map((e: any) => {
            if (utm_source === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const moveToHome = (_msisdn: string, packageName: string, date: Date) => {
    let url = window.location.origin;
    let encodedMsisdn = encode(_msisdn);
    let encodedPackage = encode(packageName);
    let encodedDate = encode(date.toString());
    url = `${url}/home?user=${encodedMsisdn}&package=${encodedPackage}&date=${encodedDate}`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/home`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    // window.location.replace(url);
  };
  return <Awareness />;
};

export default AwarenessScreen;
